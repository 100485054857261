import React from "react";

import { ChangeStepButton } from "./FirstLoginTour";
import { useRole } from "../../../assets/data/Roles";
import AdminThumbnail from "../../../assets/imgs/thumbnail-admin.png";
import ManagerThumbnail from "../../../assets/imgs/thumbnail-manager.png";
import TechThumbnail from "../../../assets/imgs/thumbnail-tech.png";
import VetThumbnail from "../../../assets/imgs/thumbnail-vet.png";

function OptionCard({ img, title, value, isSelected, onSelect }) {
  return (
    <div
      onClick={() => onSelect(value)}
      className={`group flex flex-col items-center p-6 w-56 border dark:border-gray-900 rounded-xl cursor-pointer hover:bg-indigo-200 dark:hover:bg-indigo-800 ${
        isSelected
          ? "dark:bg-indigo-800 bg-indigo-200"
          : "dark:bg-gray-700 bg-transparent"
      }`}
    >
      {img && (
        <div className="flex flex-col items-center">
          <img
            src={img}
            width={100}
            height={100}
            className="object-cover rounded-2xl overflow-clip"
          />
        </div>
      )}
      <div className="flex flex-row items-center space-x-4 mt-4">
        <h4>{title}</h4>
      </div>
    </div>
  );
}

export default function UserTypeSelectionStep({
  goToNextStep,
  goToPreviousStep,
  userType,
  setUserType,
  setIsLocumOrMobileVet,
}) {
  const { allowedRoles } = useRole();
  const userOptions = [
    {
      title: "Veterinarian",
      img: VetThumbnail,
      value: allowedRoles.DVM,
    },
    {
      title: "Vet Tech",
      img: TechThumbnail,
      value: allowedRoles.TECHNICIAN,
    },
    {
      title: "Practice Manager",
      img: ManagerThumbnail,
      value: allowedRoles.PRACTICE_MANAGER,
    },
    {
      title: "Clinic Admin/Other",
      img: AdminThumbnail,
      value: allowedRoles.OTHER,
    },
  ];

  function setUserTypeSetLocumFalse(value) {
    setUserType(value);
    setIsLocumOrMobileVet(false);
  }

  return (
    <>
      <h3 className="flex flex-row items-center">
        Which option best represents your role?
      </h3>

      <div className="flex flex-col md:flex-row items-center text-center md:space-x-6">
        {userOptions.map((option) => (
          <OptionCard
            key={option.value}
            title={option.title}
            img={option.img}
            value={option.value}
            isSelected={userType === option.value}
            onSelect={setUserTypeSetLocumFalse}
          />
        ))}
      </div>

      <div className="pt-6 flex justify-center gap-4">
        <ChangeStepButton
          onClick={goToPreviousStep}
          text={"← Back"}
        />
        <ChangeStepButton
          disabled={!userType}
          onClick={goToNextStep}
          text={"Next →"}
        />
      </div>
    </>
  );
}
