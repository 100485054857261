import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { animated, useTransition } from "react-spring";

import ProgressIndicator from "../ProgressIndicator";
import ClinicInfoStep from "./ClinicInfoStep";
import FinalStep from "./FinalStep";
import ReferralSourceStep from "./ReferralSourceStep";
import UserTypeSelectionStep from "./UserTypeSelectionStep";
import { useRole } from "../../../assets/data/Roles";
import { useAuth, useOnboarding } from "../../../hooks";
import { useDarkMode } from "../../../hooks";
import Twemoji from "../../../Twemoji";
import { SaveButton } from "../../common/buttons/SaveButton";
import { ConfirmModal } from "../../common/modals/PopupModals";

export function ChangeStepButton({
  onClick,
  disabled,
  withSpinner,
  disabledText,
  text,
}) {
  return (
    <div className="relative group mt-6 flex items-center justify-center h-12 w-44">
      {disabled ? null : (
        <div
          style={{
            boxShadow: "0px 0px 6px 4px rgba(99, 102, 241, 0.8)",
          }}
          className="w-full h-full animate-pulse rounded-full bg-transparent hover:animate-none"
        ></div>
      )}

      <div className="absolute">
        <SaveButton
          disabled={disabled}
          withSpinner={withSpinner}
          disabledText={disabledText}
          onClick={onClick}
        >
          {text}
        </SaveButton>
      </div>
    </div>
  );
}

const STEP_NAMES = [
  "Start",
  "User Type",
  "Clinic Info",
  "Source",
  "Download App",
];

export default function FirstLoginTour() {
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [shouldShowCloseModal, setShouldShowCloseModal] =
    useState(false);
  const { shouldShowFirstLoginTour, setShouldShowFirstLoginTour } =
    useOnboarding();
  const { allowedRoles } = useRole();
  const [userType, setUserType] = useState();
  const [referralSource, setReferralSource] = useState("");
  const [clinicName, setClinicName] = useState(null);
  const [clinicGroup, setClinicGroup] = useState(null);
  const [isLocumOrMobileVet, setIsLocumOrMobileVet] = useState(false);
  const { userFirstName } = useAuth();
  const navigate = useNavigate();
  const enabled = useDarkMode();

  useEffect(() => {
    setUserType(allowedRoles.DVM);
  }, [allowedRoles.DVM]);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [currentStepNumber]);

  const transitions = useTransition(currentStepNumber, {
    key: currentStepNumber,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
    exitBeforeEnter: true,
  });

  function goToNextStep() {
    const nextStep = currentStepNumber + 1;
    mixpanel.track(`Completed Onboarding Page ${nextStep}`);
    setCurrentStepNumber(nextStep);
  }

  function goToPreviousStep() {
    mixpanel.track(
      `Went Back to Onboarding Page ${currentStepNumber}`,
    );
    setCurrentStepNumber(currentStepNumber - 1);
  }

  function renderStep(i) {
    if (i === 0) {
      return (
        <>
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row space-x-4">
              <span className="text-4xl transform animate-[handwave_1s_ease-in-out_infinite]">
                <Twemoji emoji="👋" />
              </span>
              <h1 className="text-center max-w-xl w-[300px] md:w-auto md:max-w-3xl font-bold">
                Welcome to Scribenote!
              </h1>
            </div>

            <p className="text-lg w-[300px] md:w-[500px] text-center mt-10">
              Hi {userFirstName}! We’d like to ask you 3 simple
              questions to tailor your Scribenote experience better.
            </p>
          </div>
          <ChangeStepButton onClick={goToNextStep} text={"Next →"} />
        </>
      );
    }
    if (i === 1) {
      return (
        <UserTypeSelectionStep
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          setUserType={setUserType}
          userType={userType}
          setIsLocumOrMobileVet={setIsLocumOrMobileVet}
        />
      );
    }
    if (i === 2) {
      return (
        <ClinicInfoStep
          userType={userType}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          isLocumOrMobileVet={isLocumOrMobileVet}
          setIsLocumOrMobileVet={setIsLocumOrMobileVet}
          setUserType={setUserType}
          clinicName={clinicName}
          setClinicName={setClinicName}
          clinicGroup={clinicGroup}
          setClinicGroup={setClinicGroup}
          darkModeEnabled={enabled}
        />
      );
    }
    if (i === 3) {
      return (
        <ReferralSourceStep
          setReferralSource={setReferralSource}
          referralSource={referralSource}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          darkModeEnabled={enabled}
        />
      );
    }
    if (i === 4) {
      return (
        <FinalStep
          userRole={userType}
          clinicName={clinicName}
          clinicGroup={clinicGroup}
          referralSource={referralSource}
          goToPreviousStep={goToPreviousStep}
        />
      );
    }
  }

  if (shouldShowFirstLoginTour === false) {
    return (
      <div className="flex flex-col h-screen items-center justify-center space-y-10">
        <h2>Whoops, we can&apos;t take you there.</h2>
        <h5 className="max-w-xl">
          Our records indicate you&apos;ve already completed the
          tutorial. To prevent mis-use, you cannot complete the
          tutorial again. If you&apos;d like to learn more about
          Scribenote, please contact our support team.
        </h5>
        <a href="/dashboard" className="cursor-pointer">
          <h4 className="underline text-indigo-500">
            Click here to return to the Dashboard
          </h4>
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-800 px-4 py-8 md:px-20 md:py-10 flex flex-col items-center justify-between overflow-y-auto">
      <ConfirmModal
        shouldShow={shouldShowCloseModal}
        hideModal={() => setShouldShowCloseModal(false)}
        title={"Exit Onboarding?"}
        cancelText={"Yes, Exit"}
        confirmText={"Stay"}
        cancelAction={() => {
          setShouldShowFirstLoginTour(false);
          mixpanel.track("Exited Onboarding", {
            onboardingPage: currentStepNumber,
          });
          navigate("/dashboard");
        }}
        confirmAction={() => setShouldShowCloseModal(false)}
      >
        <h5>
          Are you sure? This is really important for getting you
          up-and-running with Scribenote, and you won&apos;t be able
          to revisit it!
        </h5>
      </ConfirmModal>

      <ProgressIndicator
        currentStepNumber={currentStepNumber}
        stepNames={STEP_NAMES}
      />

      <button
        onClick={() => setShouldShowCloseModal(true)}
        className="fixed top-4 right-4 text-xl"
      >
        <FontAwesomeIcon icon={faTimes} color="red" />
      </button>

      {transitions((style, i) => (
        <animated.div
          className="flex flex-1 flex-col items-center justify-evenly space-y-4 mt-10"
          style={style}
        >
          {renderStep(i)}
        </animated.div>
      ))}
    </div>
  );
}
