import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ChangeStepButton } from "./FirstLoginTour";
import CorporateGroupsList from "../../../assets/data/CGroups.js";
import { useRole } from "../../../assets/data/Roles";
import { useCurrentUser } from "../../../hooks/use-current-user";
import { Input } from "../../common/inputs/Input";

export default function ClinicInfoStep({
  userType,
  goToNextStep,
  goToPreviousStep,
  isLocumOrMobileVet,
  setIsLocumOrMobileVet,
  setUserType,
  clinicName,
  setClinicName,
  clinicGroup,
  setClinicGroup,
  darkModeEnabled,
}) {
  const { data } = useCurrentUser();
  const { allowedRoles } = useRole();

  const [selectedGroup, setSelectedGroup] = useState(
    CorporateGroupsList.find(
      (group) => group.value === clinicGroup,
    ) ?? clinicGroup,
  );

  useEffect(() => {
    if (data?.user) {
      // Extract domain from user's email
      const userDomain = data?.user?.email?.split("@")[1];
      // Find the matching group
      const matchedGroup = CorporateGroupsList.find(
        (group) =>
          group.email && userDomain === group.email.replace("@", ""),
      );
      // Set the matched group as the selected value

      if (matchedGroup) {
        setSelectedGroup(matchedGroup);
      }
    }
  }, [data?.user]);

  useEffect(() => {
    if (selectedGroup != clinicGroup) {
      setClinicGroup(selectedGroup?.value);
    }
  }, [selectedGroup]);

  return (
    <>
      <div className="flex flex-col items-center text-center space-y-10">
        <div className="space-y-2">
          <h3 className="text-center md:w-[600px]">
            Can you please tell us a bit more about your clinic?
          </h3>
          <p className="max-w-4xl">
            This will help us set up your team later on, if
            applicable.
          </p>
        </div>
        <div className="flex flex-row items-center space-x-4 w-full">
          <Input
            type="clinicName"
            placeholder="Clinic Name"
            required={true}
            disabled={isLocumOrMobileVet}
            onChange={(e) => setClinicName(e.target.value)}
            value={clinicName || ""}
          />
          {userType === allowedRoles.DVM && (
            <>
              <span className="font-semibold text-lg">OR</span>
              <div className="flex-none">
                <button
                  className={`${
                    isLocumOrMobileVet
                      ? "bg-indigo-500 text-white"
                      : "bg-transparent text-indigo-500"
                  } border-indigo-500  border rounded-full py-2 px-4 transition-all`}
                  onClick={() => {
                    setIsLocumOrMobileVet(!isLocumOrMobileVet);
                    setUserType(allowedRoles.LOCUM);
                    setClinicName(null);
                  }}
                >
                  I'm a locum or mobile vet
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-col items-center text-center space-y-10">
        <div className="flex flex-col items-center space-y-2">
          <h3 className="text-center md:w-[600px]">
            Are you eligible for discounted pricing?{" "}
          </h3>
          <p className="max-w-4xl">
            {!isLocumOrMobileVet
              ? "If your clinic is affiliated with a group or consolidator, enter it below."
              : "If you are affiliated with a group or association that represents Locums/Relief Vets, enter it below."}
          </p>
        </div>
        <div className="flex flex-row items-center justify-center space-x-4 w-full">
          <Autocomplete
            className="relative"
            freeSolo
            options={CorporateGroupsList}
            value={selectedGroup}
            onChange={(event, newValue) => setSelectedGroup(newValue)}
            ListboxProps={{
              style: {
                backgroundColor: darkModeEnabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
                color: darkModeEnabled ? "#f9fafb" : "black",
                fontSize: "14px",
              },
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  required={false}
                  size="small"
                  placeholder="Group/Consolidator Name"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    sx: {
                      color: darkModeEnabled ? "#f9fafb" : "black",
                      fontSize: 14,
                    },
                  }}
                  sx={[
                    {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    {
                      fontSize: "14px",
                      color: darkModeEnabled ? "#f9fafb" : "black",
                      backgroundColor: darkModeEnabled
                        ? "rgb(55 65 81)"
                        : "rgb(243 244 246)",
                      borderRadius: "8px",
                      flex: 1,
                      width: 400,
                    },
                  ]}
                  onChange={(e) => {
                    selectedGroup
                      ? setClinicGroup(selectedGroup?.value)
                      : setClinicGroup(e.target.value);
                  }}
                  value={clinicGroup}
                />
                {selectedGroup?.email?.replace("@", "") ===
                  data?.user?.email?.split("@")[1] && (
                  <div className="bg-indigo-500/30 text-indigo-200 font-semibold p-3 rounded-xl mt-4">
                    We've detected that you might be part of{" "}
                    {selectedGroup?.label} based on your email
                    address.
                  </div>
                )}
              </>
            )}
          />
        </div>
      </div>

      <div className="pt-6 flex justify-center gap-4">
        <ChangeStepButton
          onClick={goToPreviousStep}
          text={"← Back"}
        />
        <ChangeStepButton onClick={goToNextStep} text={"Next →"} />
      </div>
    </>
  );
}
