import React from "react";

import AppStoreLink from "../../../assets/imgs/qr-code-app-store.png";
import GooglePlayLink from "../../../assets/imgs/qr-code-play-store.png";

export default function QRCodes() {
  return (
    <div className="flex flex-row items-center space-x-4 md:space-x-24 md:py-6">
      <a
        className="flex flex-col items-center"
        href="https://apps.apple.com/us/app/scribenote/id6738748386"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={AppStoreLink}
          className="w-[140px] mb-2 rounded-xl"
        />
        <h5>For iPhone</h5>
      </a>
      <a
        className="flex flex-col items-center"
        href="https://play.google.com/store/apps/details?id=com.scribenote.rebarked.prod"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={GooglePlayLink}
          className="w-[140px] mb-2 rounded-xl"
        />
        <h5>For Android</h5>
      </a>
    </div>
  );
}
