import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

import { ChangeStepButton } from "./FirstLoginTour";

export default function ReferralSourceStep({
  setReferralSource,
  referralSource,
  goToPreviousStep,
  goToNextStep,
  darkModeEnabled,
}) {
  const { control } = useForm();

  const options = [
    {
      label: "Social Media (Instagram, Facebook, etc.)",
      value: "social",
    },
    { label: "VIN or Reddit", value: "vin_reddit" },
    { label: "From a Friend", value: "word_of_mouth" },
    { label: "Google Search", value: "google_search" },
    { label: "Webinar", value: "webinar" },
    { label: "Conference", value: "conference" },
    { label: "Podcast", value: "podcast" },
    { label: "Advertisement", value: "advertisement" },
    { label: "Other", value: "other" },
  ];

  return (
    <>
      <div className="flex flex-col items-center text-center space-y-10">
        <h3 className="text-center">
          Okay... We know we said 3 questions, but here’s one more!{" "}
          <br />
          How did you hear about Scribenote?
        </h3>

        <Select
          sx={[
            {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
            {
              fontSize: "14px",
              color: darkModeEnabled ? "#f9fafb" : "black",
              backgroundColor: darkModeEnabled
                ? "rgb(55 65 81)"
                : "rgb(243 244 246)",
              borderRadius: "8px",
              flex: 1,
              width: 400,
            },
          ]}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: darkModeEnabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
              },
            },
          }}
          value={referralSource}
          onChange={(e) => setReferralSource(e.target.value)}
          displayEmpty
        >
          <MenuItem
            value=""
            disabled
            sx={{
              fontSize: "14px",
              color: darkModeEnabled ? "#f9fafb" : "black",
            }}
          >
            Select an option
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                fontSize: "14px",
                color: darkModeEnabled ? "#f9fafb" : "black",
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="mt-10 flex justify-center gap-4">
        <ChangeStepButton
          onClick={goToPreviousStep}
          text={"← Back"}
        />
        <ChangeStepButton text={"Next →"} onClick={goToNextStep} />
      </div>
    </>
  );
}
