import { useMutation } from "@apollo/client";
import {
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useCurrentUser } from "./use-current-user.js";
import EDIT_USER from "../graphql/mutations/EditUser.js";

const onboardingContext = createContext();

export function useOnboardingContext() {
  return useContext(onboardingContext);
}

function useProvideOnboarding() {
  const [shouldShowFirstLoginTour, setShouldShowFirstLoginTour] =
    useState(null);

  const [
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  ] = useState(false);

  return {
    shouldShowFirstLoginTour,
    setShouldShowFirstLoginTour,
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  };
}

export function OnboardingProvider({ children }) {
  const onboarding = useProvideOnboarding();

  return (
    <onboardingContext.Provider value={onboarding}>
      {children}
    </onboardingContext.Provider>
  );
}

export function useOnboarding() {
  const { data, loading, refetch } = useCurrentUser();
  const [editUser] = useMutation(EDIT_USER);
  const { update } = useIntercom();

  const navigate = useNavigate();

  const {
    shouldShowFirstLoginTour,
    setShouldShowFirstLoginTour,
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  } = useOnboardingContext();

  const user = data?.user;

  useEffect(() => {
    if (!loading && user) {
      if (
        !user?.isOnboardingComplete &&
        !user?.account?.isSubscribed &&
        shouldShowFirstLoginTour === null
      ) {
        // first time a new user logs in, show them the tour
        setShouldShowFirstLoginTour(true);
        navigate("/tutorial");
      } else if (
        !user?.isOnboardingComplete &&
        !user?.account?.isSubscribed &&
        shouldShowFirstLoginTour === false
      ) {
        // new user has just finished the tour
        setShouldShowDashboardHelperText(true);
      }

      if (user?.isOnboardingComplete) {
        setShouldShowFirstLoginTour(false);
      }
    }
  }, [user, loading]);

  function userCompleteOnboarding(
    userRole,
    clinicName,
    clinicGroup,
    referralSource,
  ) {
    editUser({
      variables: {
        isOnboardingComplete: true,
        roleId: userRole,
        onboardingClinic: clinicName,
        onboardingCorporateGroup: clinicGroup,
        onboardingReferralSource:
          referralSource === "" ? null : referralSource,
      },
    }).then(() => {
      update({
        customAttributes: {
          web_onboarding_complete: true,
          job_title: userRole,
          clinic_name: clinicName,
          corporate_group: clinicGroup,
          onboarding_referral_source: referralSource,
        },
      });
      refetch();
    });
  }

  return {
    isOnboardingComplete: user?.isOnboardingComplete,
    isAppointmentsBetaUser: user?.isAppointmentsBetaUser,
    refetchUserOnboardingInfo: refetch,
    userCompleteOnboarding,
    shouldShowFirstLoginTour,
    setShouldShowFirstLoginTour,
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  };
}
